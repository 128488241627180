export function useResponsiveMenu() {
  const isOpen = useState('responsive-menu-open', () => false);

  return {
    isOpen,
    open() {
      isOpen.value = true;
    },
    close() {
      isOpen.value = false;
    },
  };
}
